import React, { useEffect, useState } from "react";
import useForm from 'react-hook-form';
import cookie from 'react-cookies';
import Messages from '../components/Messages';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ReactTooltip from 'react-tooltip';
import CardCredit from "../assets/images/card-credit.png";
import CardBancontact from "../assets/images/card-bancontact.png";

let backupItem = [];
let validatePromo = 'nocode';
let promoValue = 0;
let entrants = [];
let metadatas = [];

export default function Form(props) {
    const { register: register, errors: errors, handleSubmit: handleSubmit, reset, getValues, setValue} = useForm();
    const { register: payment, errors: paymenterrors, handleSubmit: handlePayment} = useForm();
    const [clientSecret, setClientSecret] = useState("");
    const item = props.dataitem;
    const stripe = window.Stripe('pk_live_gFPHZPFRhngDjMDOa7gIamJa00qPLoSAH6');
    // const stripe = window.Stripe('pk_test_8bCRmwUoaOMne3AZSrd3rxLE00mLKJhlq1');

    let promo = '';
    let customFields = [];
    let raceID = props.raceid;

    if(item.races[props.datanumber].customFields === undefined){
        item.races[props.datanumber].customFields = [];
    }else{
        let L = item.races[props.datanumber].customFields.length;
        for(var i=0;i<L;i++){
            if(item.races[props.datanumber].customFields[i].values != undefined){
                let customFieldsValues = item.races[props.datanumber].customFields[i].values;
                let customFieldsDatas = customFieldsValues.split('\n');
                customFields.push(customFieldsDatas);
            }else{
                customFields.push(item.races[props.datanumber].customFields[i].values);
            }
        }
    }

    // ============ CHECK IDENTIFIER
    const checkIdentifier = function(d, setValue){
        props.resetIdentifier()

        fetch('https://api.goaltiming.be/challengers/identifier/'+ d.identifier + '/' + d.identifiermail + '/' + item.EventId + '/' + props.raceid , { method: 'GET' })
        .then(res => res.json())
        .then(
            (r) => {
                if(r.message){

                    props.identifier(r.message)
                }else{

                    if(r.dob.includes('-')){
                        setValue("dob", r.dob)
                    }else{
                        var myDate = r.dob;
                        myDate = myDate.split("/");
                        var newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);
                        var year = newDate.toLocaleString("default", { year: "numeric" });
                        var month = newDate.toLocaleString("default", { month: "2-digit" });
                        var day = newDate.toLocaleString("default", { day: "2-digit" });
                        var formattedDate = year + "-" + month + "-" + day;

                        setValue("dob", formattedDate)
                    }


                    let street = r.address.split(',')
                    if(street[1] == undefined){
                        setValue("address", r.address)
                    }else{
                        let cpCity = street[1].split(' ')
                        let city = ''
    
                        for(var i=2; i<cpCity.length;i++){
                            city = city + ' ' + cpCity[i]
                        }

                        setValue("address", street[0])
                        setValue("cp",cpCity[1])
                        setValue("city", city.substring(1))
                    }

                    setValue("name", r.name)
                    setValue("firstname", r.firstname)
                    setValue("sex", r.sex)
                    setValue("email", r.email)
                    setValue("country",r.country)
                    setValue("club",r.club)
                    setValue("dossard_nbr",r.dossard)
                }
            },(error) => {
                console.log(error)
            }
        )
        


        
    }
    
    // ============ SUBMIT FORM
    const addAnother = function(d){
        let cookies = cookie.loadAll();
        let register = JSON.parse(cookies.register);

        var customFields = []
        for(var i = 0; i<item.races[props.datanumber].customFields.length; i++){
            if(item.races[props.datanumber].customFields[i].check === true){
                customFields.push({
                    name: item.races[props.datanumber].customFields[i].label,
                    value: item.races[props.datanumber].customFields[i].value,
                    id: item.races[props.datanumber].customFields[i].id
                });
            }
        }

        let date = new Date();
        let timestamp = date.getTime();

        var result = d;
            result.payement = {
                mode: 'waiting',
                date: timestamp/1000,
                promoCode: validatePromo
            };
            result.address = d.address + ', ' + d.cp + ' ' + d.city;
            result.customFields = customFields;
            result.items = backupItem;
            result.cookieToken = register;
            result.prices = {
                race: Number(props.raceprice),
                discount: props.discount,
                total: props.totalPrice
            };
            result.raceName = item.name;
            result.eventid = item.EventId;
            result.raceid = props.raceid;
            result.raceChoosed = document.querySelector('.page-runs .runs-content .choose-run input[type="radio"]:checked + label').innerHTML;
            
            
        entrants.push(result);        
        props.changeCart(entrants);
        backupItem = [];
        props.datagoals(backupItem);
        props.cdiscount(0);
        promoValue = 0;
        props.alertpromo('msg-success', 'Le code a bien été validé. Vous avez ' + result.discount + '€ de réduction sur la course.');
        // props.resetIdentifier();
        
        reset(entrants);

        // GENERATE NEW TOKEN
        fetch('https://api.goaltiming.be/races/registrations', {
            method: 'POST',
            body: JSON.stringify({
                "EventId": item.EventId,
                "RaceId": props.raceid
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.success === false){
                    props.reselectrace();
                    cookie.remove('register', { path: '/' });
                }else{
                    let date = new Date();
                    date.setTime(date.getTime() + (1440*60*1000));
                    cookie.save("register", result, {path: "/", expires: date});
                }
            },(error) => {
                console.log(error);
            }
        )
    }
    const onSubmit = function(d){
        var i = 0;
        var L = entrants.length;
        props.waiting();

        inscriptions(entrants[i], i, L);
    }

    const inscriptions = function(data, i, L){
        if(i < L){
            fetch('https://api.goaltiming.be/participants/new',{ method: 'GET' })
            .then(res => res.json())
            .then(
                (result) => {
                    result.name = data.name;
                    result.firstname = data.firstname;
                    result.email = data.email;
                    result.sex = data.sex;
                    result.dob = data.dob;
                    result.address = data.address;
                    result.country = data.country;
                    result.club = data.club;
                    result.customFields = data.customFields;
                    result.payement = data.payement;
                    result.items = data.items;
                    result.dossard_nbr = data.dossard_nbr;
                    result.accept_rgpd = data.rgpd;
                    result.accept_newsletter = data.newsletter;
                    result.is_in_good_health = data.is_in_good_health;
                    result.accept_rules = data.accept_rules;
                    result.payement_status = data.cookieToken.Token;
                    result.payement.totalprice = props.cartTotal;
                    result.payement.totalfrais = props.totalfee;
                    if(props.cartTotal <= 0){
                        result.payement.mode = 'free';
                    }
    
                    metadatas.push(data.raceid + '_' + data.eventid + '_' + result.ParticipantId);
    
                    fetch('https://api.goaltiming.be/races/participants/' + data.cookieToken.Token,{ 
                        method: 'POST' ,
                        body: JSON.stringify(result)
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            i++;
                            inscriptions(entrants[i], i, L);
                        },(error) => {
                            console.log(error);
                        }
                    );
                },(error) => {
                    console.log(error);
                }
            );
        }else{
            if(props.cartTotal > 0){
                fetch('https://api.goaltiming.be/events/session', {
                    method: 'POST',
                    body: JSON.stringify({
                        name: entrants[0].name + ' ' + entrants[0].firstname,
                        description: item.name,
                        data_description: item.name,
                        amount: Math.round(props.cartTotal*100),
                        currency: 'eur',
                        customer_email: entrants[0].email,
                        quantity: 1,
                        metadata: metadatas,
                        success_url: 'https://www.goaltiming.be/courses/' + item.EventId + '?session_id={CHECKOUT_SESSION_ID}&inscription=true',
                        cancel_url: 'https://www.goaltiming.be/courses/' + item.EventId + '?inscription=false'
                    })
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.id){
                            stripe.redirectToCheckout({
                                sessionId: result.id
                            }).then(function (result) {
                                // console.log(result);
                            });
                        }
                    },(error) => {
                        console.log(error);
                    }
                )
            }else{
                window.location.replace('https://www.goaltiming.be/courses/' + item.EventId + '?inscription=true');
            }
        }
    }
    
    const handleChange = function(e){
        var maxVal = e.target.attributes.max;
        e.preventDefault();
        if(e.target.value < 0){
            e.target.value = 0;
        }

        if(maxVal != undefined && maxVal.nodeValue < e.target.value){
            e.target.value = maxVal.nodeValue;
        }

        backupItem[e.target.attributes.datakey.nodeValue] = {
            number: Math.round(e.target.value),
            name: e.target.attributes.dataname.nodeValue,
            price: e.target.attributes.dataprice.nodeValue,
            totalprice: e.target.value*e.target.attributes.dataprice.nodeValue
        };

        props.datagoals(backupItem);
    }

    const changePromo = function(e){
        e.preventDefault();
        promo = e.target.value;
    }

    const checkPromo = function(e){
        e.preventDefault();

        fetch('https://api.goaltiming.be/events/'+item.EventId+'/promos', {
            method: 'POST',
            body: JSON.stringify({"code": promo})
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.success === true){
                    var totalPromo = 0;
                    validatePromo = promo;
                    promoValue = Number(result.discount);

                    for(var i = 0; i<item.races[props.datanumber].customFields.length; i++){
                        if(item.races[props.datanumber].customFields[i].check === true){
                            totalPromo = Number(totalPromo) + Number(item.races[props.datanumber].customFields[i].reduction);
                        }
                    }
            
                    totalPromo = Number(totalPromo) + Number(promoValue);
                    props.cdiscount(Number(totalPromo));

                    props.alertpromo('msg-success show', 'Le code a bien été validé. Vous avez ' + result.discount + '€ de réduction sur la course.');

                }else{
                    props.alertpromo('msg-error show', 'Code erroné ou déjà utilisé');
                }
            },(error) => {
                console.log(error);
            }
        )
    }

    const customField = function(e){
        e.preventDefault();
        var value = e.target.value;
        var target = e.target.attributes.target.nodeValue;
        var totalPromo = 0;

        if(value !== ''){
            item.races[props.datanumber].customFields[target].check = true;
            item.races[props.datanumber].customFields[target].value = value;
        }else{
            item.races[props.datanumber].customFields[target].check = false;
            item.races[props.datanumber].customFields[target].value = '';
        }

        for(var i = 0; i<item.races[props.datanumber].customFields.length; i++){
            if(item.races[props.datanumber].customFields[i].check === true){
                totalPromo = Number(totalPromo) + Number(item.races[props.datanumber].customFields[i].reduction);
            }
        }

        totalPromo = Number(totalPromo) + Number(promoValue);
        props.cdiscount(Number(totalPromo));
    }

    return (
        <div>

        <form onSubmit={handleSubmit(addAnother)} className="step" id="step_2">

            {item.races[props.datanumber].challenge != '' && item.races[props.datanumber].challenge != undefined ? (
                <div>
                    <h2>Pré-inscription (Challenge)</h2>

                    <div className="row">
                        <div className="col-md-4">
                            <label>Email</label>
                            <input type="email" name="identifiermail" placeholder="email@email.be" ref={register} />
                        </div>
                        <div className="col-md-5">
                            <label>Clé</label>
                            <input type="text" name="identifier" placeholder="xxxxxXxxXXXXxxxxXXXXXxxxxx" ref={register} />
                                            </div>
                        <div className="col-md-3">
                            <button type="button" className="button submitbtn orange" onClick={() => {
                                    checkIdentifier(getValues(), setValue)
                                }}>
                                Vérifier
                            </button>
                        </div>

                        <input type="hidden" name="dossard_nbr" ref={register} />
                        
                        <small className="form-text form-error"><span style={{color: '#FFB140', margin: '0 0 0 10px'}}>Remplissez l’email (utilisé lors de la pré-inscription) et votre clé que vous avez reçu lors de votre pré-inscription au challenge "{item.races[props.datanumber].challenge}".</span></small>
                        
                        <br /><br />
                        
                        <small className="form-text form-error"><span style={{margin: '0 0 0 10px'}}>{props.identifiermsg}</span></small>
                        <hr />
                    </div>
                </div>
            ) : ('')}

            {props.trad ? (
                <h2>2. Vos informations / Uw gegevens</h2>
            ) : (
                <h2>2. Vos informations</h2>
            )}

            <div>
                <div className="row">
                    <div className="col-md-6">
                        {props.trad ? (
                            <label>Nom / Naam *</label>
                        ) : (
                            <label>Nom *</label>
                        )}
                        <input type="text" name="name" placeholder="Nom" ref={register({required: true})} />
                        {props.trad ? (
                        <small className="form-text form-error">{errors.name && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                    <div className="col-md-6">
                        {props.trad ? (
                            <label>Prénom / Voornaam *</label>
                        ) : (
                            <label>Prénom *</label>
                        )}
                        <input type="text" name="firstname" placeholder="Prénom" ref={register({required: true})} />
                        {props.trad ? (
                        <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        {props.trad ? (
                            <label>Sexe / Geslacht *</label>
                        ) : (
                            <label>Sexe *</label>
                        )}
                        <div className="row">
                            {props.trad ? (
                                <div className="col"><input type="radio" name="sex" value="F" ref={register({required: true})} /> Femme / Vrouw</div>
                            ) : (
                                <div className="col"><input type="radio" name="sex" value="F" ref={register({required: true})} /> Femme</div>
                            )}
                            
                            {props.trad ? (
                                <div className="col"><input type="radio" name="sex" value="H" id="sexe_woman" ref={register({required: true})} /> Homme / Man</div>
                            ) : (
                                <div className="col"><input type="radio" name="sex" value="H" id="sexe_woman" ref={register({required: true})} /> Homme</div>
                            )}
                        </div>
                        <br />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.sex && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.sex && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                    <div className="col-md-6">
                        {props.trad ? (
                            <label>Date de naissance / Geboortedatum *</label>
                        ) : (
                            <label>Date de naissance *</label>
                        )}
                        <input type="date" name="dob" ref={register({required: true})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.dob && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.dob && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <label>E-mail *</label>
                        <input type="text" name="email" placeholder="email@email.be" ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.email && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.email && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        {props.trad ? (
                            <label>Adresse / Adres *</label>
                        ) : (
                            <label>Adresse *</label>
                        )}
                        <input type="text" name="address" placeholder="Adresse" ref={register({required: true})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.address && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.address && <span>Ce champ est requis</span>}</small>
                        )}
                    </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                    {props.trad ? (
                        <label>Code Postal / Postcode *</label>
                    ) : (
                        <label>Code Postal *</label>
                    )}
                    <input type="number" name="cp" placeholder="Code Postal" ref={register({required: true})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.cp && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.cp && <span>Ce champ est requis</span>}</small>
                        )}
                </div>
                <div className="col-md-8">
                    {props.trad ? (
                        <label>Ville / Gemeente *</label>
                    ) : (
                        <label>Ville *</label>
                    )}
                    <input type="text" placeholder="Ville" name="city" ref={register({required: true})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.city && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.city && <span>Ce champ est requis</span>}</small>
                        )}
                </div>
                </div>

                <div className="row">
                <div className="col-md-12">
                    {props.trad ? (
                        <label>Pays / Land *</label>
                    ) : (
                        <label>Pays *</label>
                    )}
                    <input type="text" placeholder="Pays" name="country" ref={register({required: true})} />
                        {props.trad ? (
                            <small className="form-text form-error">{errors.country && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors.country && <span>Ce champ est requis</span>}</small>
                        )}
                </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <label>Club</label>
                        <input type="text" name="club" placeholder="Club" ref={register} />
                    </div>
                </div>
            </div>

            {props.trad ? (
                <h2>3. Pour terminer... / Om af te ronden</h2>
            ) : (
                <h2>3. Pour terminer...</h2>
            )}

            {item.races[props.datanumber].customFields.map((item, i) =>
                <div className="row" key={i}>
                    {item.values ? (
                    <div className="col-md-12">
                        <label>
                            {item.tooltip ? (
                                <span className="customTooltip">
                                    <button data-tip data-for={'customTooltip' + i}>i</button>
                                    <ReactTooltip id={'customTooltip' + i} place="right" effect="solid" className="bullet">
                                        {item.tooltip}
                                    </ReactTooltip>
                                </span>
                            ) : ('')}
                            {item.label}
                        </label>
                        <select name={'custom_' + i} target={i} ref={register({required: true})} onBlur={customField} onChange={customField}>
                            <option value="">-- Choisir --</option>
                            {customFields[i].map((value, j) =>
                                <option value={value} key={j}>{value}</option>
                            )}
                        </select>
                        <br />
                        <br />
                        {props.trad ? (
                            <small className="form-text form-error">{errors['custom_' + i] && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                        ) : (
                            <small className="form-text form-error">{errors['custom_' + i] && <span>Ce champ est requis</span>}</small>
                        )}
                        <br />
                        <br />
                    </div>
                    ) : (
                    <div className="col-md-12">
                        <label>
                            {item.tooltip ? (
                                <span className="customTooltip">
                                    <button data-tip data-for={'customTooltip' + i}>i</button>
                                    <ReactTooltip id={'customTooltip' + i} place="right" effect="solid" className="bullet">
                                        {item.tooltip}
                                    </ReactTooltip>
                                </span>
                            ) : ('')}
                            {item.label}
                        </label>
                        <input type="text" name={'custom_' + i} target={i} ref={register} onBlur={customField} onChange={customField} />
                    </div>
                    )}
                </div>
            )}

            {item.items.map((it, i) =>
                <div key={i}>
                {it.link == raceID || it.link == '' || it.link == undefined ? (
                <div className="row item-shop">
                <div className="col-md-6 item-img">
                    <h3>
                        {it.tooltip ? (
                            <span className="itemTooltip">
                                <button data-tip data-for={'itemTooltip' + i}>i</button>
                                <ReactTooltip id={'itemTooltip' + i} place="right" effect="solid" className="bullet">
                                    {it.tooltip}
                                </ReactTooltip>
                            </span>
                        ) : ('')}
                        {it.name}
                    </h3>
                    <div>
                        {it.image != '' ? (
                            <img src={'https://www.goaltiming.be' + it.image} alt={it.name} width="auto" height="100px" />
                        ) : ('')}
                    </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 item-quantity">
                    {item.EventId == 'c60791d0-6ea8-11ee-8f83-efecc632a214' ? (
                        <label>{it.price} EUR</label>
                        ) : (
                        <label>{it.price}€/pièce</label>
                    )}
                    {it.max == 0 || it.max == undefined ? (
                        <div>
                            <input type="number" min="0" placeholder="0" step="1" name={'item_' + i} dataname={it.name} datakey={i} dataprice={it.price} onInput={handleChange} />
                        </div>
                    ) : (
                        <div>
                            <input type="number" min="0" max={it.max} placeholder="0" step="1" name={'item_' + i} dataname={it.name} datakey={i} dataprice={it.price} onInput={handleChange} />
                        </div>
                    )}
                </div>
                {item.EventId == 'c60791d0-6ea8-11ee-8f83-efecc632a214' ? (
                    <div style={{fontSize: '11px', width: '100%'}}>
                        Possibilité de rajouter des dons.<br />
                        Mettre le montant souhaité dans la case correspondante pendant l'inscription.<br /><br />

                        Dons au profit de l'asbl ASCTR (Association Sportive du Centre de Traumatologie et Réadaptation)
                        dont la mission est de favoriser la pratique d’activités physiques
                        et/ou sportives comme moyen de réadaptation pour des personnes atteintes d’un handicap physique
                        ou/et cognitif dans une optique de réinsertion sociale, familiale et professionnelle.<br /><br />
                        
                        L’argent reçu permettra d’acquérir du nouveau matériel adapté.<br />
                        Avec notamment l'acquisition d'une assistance électrique pour un handbike.<br />
                        Afin de rendre plus accessible la pratique de ce moyen de déplacement aux débutants
                        dans le but de pouvoir participer à plus de courses avec nos patients et/ou sportifs.
                    </div>
                ) : (
                    <div></div>
                )}
                </div>
                ) : ('')}
                </div>
            )}
            <div className="rgpd">
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_rgpd" id="rgpd" ref={register({required: true})} defaultChecked /></div>
                    {props.trad ? (
                        <div className="col-11"><label htmlFor="rgpd">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de l'organisation de cet événement. <br /> Ik geef Goal Timing en de organisatoren van het evenement de toestemming om mijn gegevens in het kader van de organisatie van dit evenement te gebruiken</label></div>
                    ) : (
                        <div className="col-11"><label htmlFor="rgpd">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de l'organisation de cet événement.</label></div>
                    )}
                </div>
                {props.trad ? (
                    <small className="form-text form-error">{errors.accept_rgpd && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                ) : (
                    <small className="form-text form-error">{errors.accept_rgpd && <span>Ce champ est requis</span>}</small>
                )}
                
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_newsletter" id="newsletter" ref={register} /></div>
                    {props.trad ? (
                        <div className="col-11"><label htmlFor="accept_newsletter">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de publicité et de mailings. <br /> Ik geef Goal Timing en de organisatoren van het evenement de toestemming om mijn gegevens in het kader van publiciteit en nieuwsbrieven te gebruiken</label></div>
                    ) : (
                        <div className="col-11"><label htmlFor="accept_newsletter">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de publicité et de mailings.</label></div>
                    )}
                </div>
                {props.trad ? (
                    <small className="form-text form-error">{errors.accept_newsletter && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                ) : (
                    <small className="form-text form-error">{errors.accept_newsletter && <span>Ce champ est requis</span>}</small>
                )}
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="is_in_good_health" id="is_in_good_health" ref={register({required: true})} /></div>
                    {props.trad ? (
                        <div className="col-11"><label htmlFor="is_in_good_health">Je déclare être en bonne condition physique et apte à la pratique du sport en compétition. <br /> Ik verklaar in goede fysieke gezondheid te zijn en geschikt te zijn voor het beoefenen van sport in competitieverband</label></div>
                    ) : (
                        <div className="col-11"><label htmlFor="is_in_good_health">Je déclare être en bonne condition physique et apte à la pratique du sport en compétition.</label></div>
                    )}
                </div>
                {props.trad ? (
                    <small className="form-text form-error">{errors.is_in_good_health && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                ) : (
                    <small className="form-text form-error">{errors.is_in_good_health && <span>Ce champ est requis</span>}</small>
                )}
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_rules" id="accept_rules" ref={register({required: true})} /></div>
                    {props.trad ? (
                    <div className="col-11"><label htmlFor="accept_rules">J'accepte le <a href={'https://www.goaltiming.be' + item.addRules} target="_blank">règlement de la course</a>. <br /> Ik aanvaard <a href={'https://www.goaltiming.be' + item.addRules} target="_blank">het reglement van de wedstrijd</a>.</label></div>
                ) : (
                        <div className="col-11"><label htmlFor="accept_rules">J'accepte le <a href={'https://www.goaltiming.be' + item.addRules} target="_blank">règlement de la course</a>.</label></div>
                    )}
                </div>
                {props.trad ? (
                    <small className="form-text form-error">{errors.accept_rules && <span>Ce champ est requis / Dit veld is verplicht</span>}</small>
                ) : (
                    <small className="form-text form-error">{errors.accept_rules && <span>Ce champ est requis</span>}</small>
                )}
            </div>

            <div className="block-payment">
                <hr />
                <div className="row">
                    {props.trad ? (
                        <div className="col-md-12"><strong>Code promo / Promocode</strong></div>
                    ) : (
                        <div className="col-md-12"><strong>Code promo</strong></div>
                    )}
                    <div className="row">
                        {props.trad ? (
                            <div className="col-6"><input type="text" onChange={changePromo} className="inputPromo" /></div>
                        ) : (
                            <div className="col-9"><input type="text" onChange={changePromo} className="inputPromo" /></div>
                        )}
                        {props.trad ? (
                            <div className="col-6"><button className="button orange" onClick={checkPromo}>Valider / Toevoegen</button></div>
                        ) : (
                            <div className="col-3"><button className="button orange" onClick={checkPromo}>Valider</button></div>
                        )}
                    </div>
                </div>
                <Messages type={props.typepromo} message={props.msgpromo} />
            </div>
            {props.trad ? (
                <button type="button" className="button submitbtn orange" onClick={handleSubmit(addAnother)}>Ajouter au panier / Toevoegen aan mijn mandje</button>
            ) : (
                <button type="button" className="button submitbtn orange" onClick={handleSubmit(addAnother)}>Ajouter au panier</button>
            )}
        </form>
        {props.cart.length > 0 ? (
            <form onSubmit={handlePayment(onSubmit)}>
                <div className="block-payment">
                <hr />
                <div className="row">
                    {props.trad ? (
                        <div className="col-md-12"><strong>Mode de paiement / Betalingswijzen</strong></div>
                    ) : (
                        <div className="col-md-12"><strong>Mode de paiement</strong></div>
                    )}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <img src={CardBancontact} alt="Bancontact" />
                        <img src={CardCredit} alt="Carte de crédit" />
                    </div>
                </div>
                </div>
                    {props.trad ? (
                        <a href="#top" className={"button submitbtn orange addother " + props.waitingmsg}>Ajouter un autre coureur <br /> Een nieuwe renner toevoegen</a>
                    ) : (
                        <a href="#top" className={"button submitbtn orange addother " + props.waitingmsg}>Ajouter un autre coureur</a>
                    )}
                    {props.trad ? (
                        <button type="button" className={"button submitbtn orange " + props.waitingmsg} onClick={handlePayment(onSubmit)}>Confirmer mon panier et payer <br /> Mijn mandje bevestigen en betalen</button>
                    ) : (
                        <button type="button" className={"button submitbtn orange " + props.waitingmsg} onClick={handlePayment(onSubmit)}>Confirmer mon panier et payer</button>
                    )}
                <p className={"waiting-message " + props.waitingmsg}>Redirection vers la plateforme de paiement en cours, veuillez patienter...</p>
            </form>
        ) : ('')}
                    
        </div>
    );
}